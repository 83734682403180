import React, { useState, useContext, useRef } from 'react'
import '../../App.css'
import './style.css'
import { Button, Divider, Form, Input, Image, Modal, Upload, message, Radio, Select } from 'antd'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
// const {  LoadingOutlined, PlusOutlined  } = icons;
import { ReactCrop } from 'react-image-crop'
import axios from 'axios'
import { MainStore } from 'core/store'
import { useNavigate } from 'react-router-dom'
import Loading from 'components/loading'
import moment from 'moment/moment'

import ReCAPTCHA from 'react-google-recaptcha'

const { Option } = Select

const order_type = [
  { index: 0, value: 'Store', prefix: 'S' },
  { index: 1, value: 'Meituan', prefix: 'M' },
]

const branch = [
  { index: 0, value: 'Terminal21' },
  { index: 1, value: 'ICONSIAM' },
]

let cropTimeout = null

export default function HomeContainer() {
  // Slip Valiable
  const [isPreviewSlip, setIsPreviewSlip] = useState(false)
  const [isAcceprRecaptcha, setIsAcceptRecaptcha] = useState(false)
  const [isConfirmOwnCup, setIsConfirmOwnCup] = useState(false)
  const [selectedSlipFile, setSelectedSlipFile] = useState(null)
  const [selectedSlipFileNew, setSelectedSlipFileNew] = useState(null)

  const [submitData, setSubmitData] = useState(null)

  // Personal Image Valiable
  const [isCropping, setIsCropping] = useState(false)
  const [selectedPersonalFile, setSelectedPersonalFile] = useState(null)
  const [selectedPersonalFileNew, setSelectedPersonalFileNew] = useState(null)
  const [selectedPersonalFileCrop, setSelectedPersonalFileCrop] = useState(null)

  const [slipUploadUrl, setSlipUploadUrl] = useState(null)
  const [personalUploadUrl, setPersonalUploadUrl] = useState(null)
  const [orderNo, setOrderNo] = useState('')
  const [orderType, setOrderType] = useState(order_type[0].prefix)

  const imgRef = useRef(null)
  const formRef = useRef(null)

  // Context
  const { isLoading, setIsLoading } = useContext(MainStore)
  const [messageApi, contextHolder] = message.useMessage()
  const navigate = useNavigate()

  const alphanumericPattern = /^[a-zA-Z0-9]*$/

  const handleInputChange = (e) => {
    const inputValue = e.target.value
    if (inputValue === '' || alphanumericPattern.test(inputValue)) {
      setOrderNo(inputValue)
    } else {
      setOrderNo(null)
    }
  }

  // Crop Props
  const [crop, setCrop] = useState({
    unit: '%', // Can be 'px' or '%'
    x: 0,
    y: 0,
    width: 100,
    height: 100,
    aspect: 2 / 3,
  })

  const handleRecaptchaVerify = (response) => {
    if (response) {
      setIsAcceptRecaptcha(true)
      console.log('reCAPTCHA response:', response)
    }
  }

  async function handleSubmitForm(values, coppingImage, personalImage) {
    setIsLoading(true)

    if (coppingImage && personalImage) {
      const params = {
        name: values.first_name,
        surname: values.last_name,
        tel: values.phone,
        email: values.email,
        personal_image_url: process.env.REACT_APP_GOOGLE_DRIVE_VIEW_IMAGE_URL?.replace('%url', coppingImage),
        slip_image_url: process.env.REACT_APP_GOOGLE_DRIVE_VIEW_IMAGE_URL?.replace('%url', personalImage),
        order_type: order_type[values.order_type].value,
        branch: branch[values.branch].value,
        order_no: values.order_no,
      }

      axios
        .post(`${process.env.REACT_APP_API_URL}/api/v1/update-sheet`, params)
        .then((response) => {
          setIsLoading(false)
          if (!response.data.success) {
            messageApi.open({
              type: 'error',
              content: `Error: ${response.data.message}`,
              duration: 5,
            })

            setIsConfirmOwnCup(false)
            setSubmitData(null)

            axios.delete(`${process.env.REACT_APP_API_URL}/api/v1/delete-image`, { params: { imageId: coppingImage } })
            axios.delete(`${process.env.REACT_APP_API_URL}/api/v1/delete-image`, { params: { imageId: personalImage } })
          } else {
            navigate('/result')
            messageApi.open({
              type: 'success',
              content: 'Submit success.',
            })
          }
        })
        .catch((error) => {
          messageApi.open({
            type: 'error',
            content: 'Error please try again.',
          })

          setIsLoading(false)
        })
    } else {
      messageApi.open({
        type: 'error',
        content: 'Error: Please upload image.',
      })
    }
  }

  function generateImageUrl(file) {
    return URL.createObjectURL(file)
  }

  function onClearImage(type) {
    if (type === 'slip') {
      setSelectedSlipFile(null)
      setSelectedSlipFileNew(null)
    } else {
      setSelectedPersonalFile(null)
    }
  }

  function handleCropComplete(crop) {
    clearTimeout(cropTimeout)
    cropTimeout = setTimeout(() => {
      if (crop.width && crop.height) {
        const canvas = document.createElement('canvas')
        const image = document.getElementById('copping-image')
        const scaleX = image.naturalWidth / image.width
        const scaleY = image.naturalHeight / image.height
        const ctx = canvas.getContext('2d')
        const watermarkText = `${moment().format('YYYY-MM-DD')}-${orderType}-${orderNo}` // Replace with your desired watermark text
        const watermarkFont = '24px Kanit' // Replace with your desired watermark font
        const targetWidth = 960
        const targetHeight = 1440

        canvas.width = targetWidth
        canvas.height = targetHeight

        // Calculate the scaled crop dimensions
        const scaledCropWidth = crop.width * scaleX
        const scaledCropHeight = crop.height * scaleY

        // Calculate the scaled crop position
        const scaledCropX = crop.x * scaleX
        const scaledCropY = crop.y * scaleY

        // Calculate the resize ratio
        const resizeRatio = Math.max(targetWidth / scaledCropWidth, targetHeight / scaledCropHeight)

        // Calculate the resized dimensions
        const resizedWidth = scaledCropWidth * resizeRatio
        const resizedHeight = scaledCropHeight * resizeRatio

        // Calculate the position to center the resized image
        const resizedX = (targetWidth - resizedWidth) / 2
        const resizedY = (targetHeight - resizedHeight) / 2

        // Draw the image on the canvas with resizing and cropping
        ctx.drawImage(image, scaledCropX, scaledCropY, scaledCropWidth, scaledCropHeight, resizedX, resizedY, resizedWidth, resizedHeight)

        // Add the watermark
        ctx.font = watermarkFont
        const textWidth = ctx.measureText(watermarkText).width
        const textHeight = parseInt(watermarkFont, 10) // Extract font size from the font string
        const x = targetWidth - textWidth - 15 // Adjust the x-coordinate of the watermark
        const y = targetHeight - textHeight + 5 // Adjust the y-coordinate of the watermark

        // Set shadow properties
        ctx.shadowColor = 'rgba(0, 0, 0, 1)'
        ctx.shadowOffsetX = 1
        ctx.shadowOffsetY = 1
        ctx.shadowBlur = 2

        ctx.fillStyle = 'rgba(255, 255, 255, 0.5)'
        ctx.fillText(watermarkText, x, y)

        const croppedImageUrl = canvas.toDataURL('image/jpeg')
        setSelectedPersonalFileCrop(croppedImageUrl)

        canvas.toBlob((blob) => {
          const reader = new FileReader()
          reader.onload = () => {
            const base64Data = reader.result.split(',')[1]
            setSelectedPersonalFileNew(base64Data)
          }
          reader.readAsDataURL(blob)
        }, 'image/jpeg')
      }
    }, 250)
  }

  function handleUploadSlipComplete(file) {
    setIsLoading(true)

    const maxSize = 5 * 1024 * 1024

    if (file.size > maxSize) {
      message.error('File size exceeds the limit (5MB)')
      setIsLoading(false)
      return false
    }

    setSelectedSlipFile(file)
    setIsPreviewSlip(true)
  }

  function onCancelCopping() {
    setIsCropping(false)
    setSelectedPersonalFileCrop(null)
    setSelectedPersonalFileNew(null)
    setSelectedPersonalFile(null)
  }

  function onCancelUploadSlip() {
    setIsPreviewSlip(false)
    setSelectedSlipFile(null)
  }

  function onSubmitMyOwnCup(values) {
    if (!isAcceprRecaptcha) {
      messageApi.open({
        type: 'error',
        content: 'Error: Please accept reCAPTCHA.',
      })
      return
    }

    if (selectedPersonalFileNew) {
      setSubmitData(values)
      setIsConfirmOwnCup(true)
    } else {
      messageApi.open({
        type: 'error',
        content: 'Error: Please upload image.',
      })
    }
  }

  function onSubmitCopping(values) {
    setIsLoading(true)
    setIsConfirmOwnCup(true)
    const formData = new FormData()
    formData.append('file', selectedPersonalFileNew)
    formData.append('fileName', `${moment().format('YYYY-MM-DD')}-${orderType}-${orderNo}`)
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/v1/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        if (response.data.data.id) {
          setPersonalUploadUrl(response.data.data.id)
          setIsCropping(false)
          // setIsLoading(false)

          messageApi.open({
            type: 'success',
            content: 'Image uploaded.',
          })

          onSubmitSlipUpload(values, response.data.data.id)

          return true
        } else {
          setIsConfirmOwnCup(false)
          setSubmitData(null)
          return false
        }
      })
      .catch((error) => {
        messageApi.open({
          type: 'error',
          content: 'Error to upload Copping image.',
        })

        onCancelCopping()
        setIsCropping(false)
        setIsLoading(false)

        return false
      })
  }

  function onSubmitSlipUpload(values, coppingImage) {
    if (selectedSlipFile) {
      setIsLoading(true)
      const formData = new FormData()

      formData.append('file', selectedSlipFileNew)
      formData.append('fileName', `${moment().format('YYYY-MM-DD')}-${orderType}-${orderNo}`)

      axios
        .post(`${process.env.REACT_APP_API_URL}/api/v1/upload-slip`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          if (response.data.data.id) {
            setSlipUploadUrl(response.data.data.id)
            setIsPreviewSlip(false)
            // setIsLoading(false)

            messageApi.open({
              type: 'success',
              content: 'Image uploaded.',
            })

            handleSubmitForm(values, coppingImage, response.data.data.id)
            return true
          } else {
            axios.delete(`${process.env.REACT_APP_API_URL}/api/v1/delete-image`, { params: { imageId: coppingImage } })
            messageApi.open({
              type: 'error',
              content: 'Error: Something went wrong.',
            })
            setIsPreviewSlip(false)
            setIsLoading(false)
            return false
          }
        })
        .catch((error) => {
          setIsConfirmOwnCup(false)
          setSubmitData(null)
          messageApi.open({
            type: 'error',
            content: 'Error to upload image.',
          })

          axios.delete(`${process.env.REACT_APP_API_URL}/api/v1/delete-image`, { params: { imageId: coppingImage } })
          setIsPreviewSlip(false)
          setIsLoading(false)

          return false
        })
    } else {
      messageApi.open({
        type: 'error',
        content: 'Error: Please upload image.',
      })
    }
  }

  const uploadButton = (
    <div>
      {isLoading ? <LoadingOutlined style={{ color: '#f2e9db' }} /> : <PlusOutlined style={{ color: '#f2e9db' }} />}
      <div
        style={{
          marginTop: 8,
          color: '#f2e9db',
        }}
      >
        Upload
      </div>
    </div>
  )

  const beforeUpload = (file) => {
    const maxSize = 5 * 1024 * 1024
    const reader = new FileReader()

    if (file.size > maxSize) {
      message.error('File size exceeds the limit (5MB)')
      setIsLoading(false)
      return
    }

    setIsLoading(true)

    reader.readAsDataURL(file)
    reader.addEventListener('load', (event) => {
      const _loadedImageUrl = event.target.result
      const image = document.createElement('img')
      setSelectedPersonalFile(file)
      setIsCropping(true)

      image.src = _loadedImageUrl
      image.addEventListener('load', () => {
        setTimeout(() => {
          setIsLoading(false)

          const imgWidth = imgRef.current.clientWidth
          const imgHeight = imgRef.current.clientHeight

          const imgAspectRatio = imgWidth / imgHeight
          let cropWidth, cropHeight, x, y

          if (imgAspectRatio > 2 / 3) {
            cropHeight = imgHeight
            cropWidth = (imgHeight * 2) / 3
            x = (imgWidth - cropWidth) / 2
            y = 0
          } else {
            cropWidth = imgWidth
            cropHeight = (imgWidth * 3) / 2
            x = 0
            y = (imgHeight - cropHeight) / 2
          }

          setCrop({
            unit: 'px',
            x,
            y,
            width: cropWidth,
            height: cropHeight,
            aspect: 2 / 3,
          })

          handleCropComplete({
            unit: 'px',
            x,
            y,
            width: cropWidth,
            height: cropHeight,
            aspect: 2 / 3,
          })
        }, 1000)
      })
    })
  }

  return (
    <div className="App">
      {contextHolder}
      {isLoading && <Loading title="Uploading..." />}
      <Modal open={isCropping} centered footer={false} closable={false} onClose={() => setIsCropping(false)}>
        {selectedPersonalFile && (
          <>
            <ReactCrop crop={crop} onChange={(c) => setCrop(c)} onComplete={handleCropComplete} locked>
              <img ref={imgRef} id="copping-image" src={generateImageUrl(selectedPersonalFile)} alt="pernonal-img" style={{ width: '100%' }} />
            </ReactCrop>
            <div style={{ display: 'flex', gap: 10, justifyContent: 'center', width: '100%', alignItems: 'center' }}>
              <Button type="primary" style={{ width: '100%' }} onClick={() => setIsCropping(false)}>
                ยืนยันการเลือก
              </Button>
              <Button onClick={() => onCancelCopping()} style={{ width: '100%' }}>
                ยกเลิก
              </Button>
            </div>
          </>
        )}
      </Modal>

      <Modal open={isConfirmOwnCup} footer={false} centered closable={false} onClose={() => setIsConfirmOwnCup(false)}>
        <div style={{ textAlign: 'center' }}>
          <h1>Submit My Own Cup</h1>
          <div style={{ textAlign: 'left' }}>
            <div>
              <b>Slip Type. : </b>
              {order_type[submitData?.order_type]?.value}
            </div>
            <div>
              <b>Order no. : </b>
              {submitData?.order_no}
            </div>
            <div>
              <b>Pick up branch : </b>
              {branch[submitData?.branch]?.value}
            </div>
            <div>
              <b>Name : </b>
              {submitData?.first_name} {submitData?.last_name}
            </div>
            <div>
              <b>E-mail : </b>
              {submitData?.email}
            </div>
            <div>
              <b>Phone number : </b>
              {submitData?.phone}
            </div>
            <div>
              <b>Order Slip Reference : </b>
            </div>
            <div style={{ textAlign: 'center' }}>{selectedSlipFile && <Image src={generateImageUrl(selectedSlipFile)} alt="slip-img" style={{ height: '150px', width: 'unset' }} />}</div>
            <div>
              <b>Your selfie or image for your personalized mama cup : </b>
            </div>
            <div style={{ textAlign: 'center' }}>{selectedPersonalFileCrop && <Image src={selectedPersonalFileCrop} alt="pernonal-img-crop" style={{ height: '150px', width: 'unset' }} />}</div>
          </div>
          <div style={{ marginTop: 20, fontSize: 'clamp(14px, 2.5vw, 16px)' }}>Your personalized mama cup will be ready to pick up at selected pick up branch after {moment().add('d', 14).format('YYYY-DD-MM')}. Please visit the branch and contact staff to pick up the cup</div>
          <div className="btn-submit-footer" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 10, marginTop: 20 }}>
            <Button type="primary" style={{ width: '100%' }} onClick={() => onSubmitCopping(submitData)}>
              Confirm
            </Button>
            <Button onClick={() => setIsConfirmOwnCup(false)} style={{ width: '100%' }}>
              Cancel
            </Button>
          </div>
        </div>
      </Modal>

      <Modal open={isPreviewSlip} centered footer={false} closable={false} onClose={() => setIsPreviewSlip(false)}>
        {selectedSlipFile && (
          <div style={{ display: 'flex', flexDirection: 'column', gap: 10, alignItems: 'center', justifyContent: 'center' }}>
            <Image
              id="slip-image"
              style={{ maxWidth: 960 }}
              src={generateImageUrl(selectedSlipFile)}
              onLoad={(e) => {
                const img = e.target
                const canvas = document.createElement('canvas')
                const ctx = canvas.getContext('2d')

                canvas.width = img.width
                canvas.height = img.height

                ctx.drawImage(img, 0, 0, img.width, img.height)

                canvas.toBlob((blob) => {
                  const reader = new FileReader()
                  reader.onload = () => {
                    const base64Data = reader.result.split(',')[1]
                    setSelectedSlipFileNew(base64Data)
                    setIsLoading(false)
                  }
                  reader.readAsDataURL(blob)
                }, 'image/jpeg')
              }}
            />
            <div style={{ display: 'flex', gap: 10, justifyContent: 'center', width: '100%', alignItems: 'center' }}>
              <Button type="primary" style={{ width: '100%' }} onClick={() => setIsPreviewSlip(false)}>
                ยืนยันการเลือก
              </Button>
              <Button onClick={() => onCancelUploadSlip()} style={{ width: '100%' }}>
                ยกเลิก
              </Button>
            </div>
          </div>
        )}
      </Modal>

      <div style={{ padding: 20 }} className="main-container">
        {/* <header className="App-header"> */}
        <div style={{ zIndex: 99, position: 'relative' }}>
          <h1 style={{ textAlign: 'center', fontSize: 36 }}>MY OWN CUP</h1>
          <Divider />
          <Form ref={formRef} layout="vertical" onFinish={(values) => onSubmitMyOwnCup(values)}>
            <Form.Item initialValue={0} style={{ width: '100%' }} name="order_type" label="Slip Type" rules={[{ required: true, message: 'Please enter First Name' }]}>
              <Radio.Group onChange={(e) => setOrderType(order_type[e.target.value].prefix)}>
                {order_type.map((type) => {
                  return <Radio value={type.index}>{type.value}</Radio>
                })}
              </Radio.Group>
            </Form.Item>
            <Form.Item
              style={{ width: '100%' }}
              name="order_no"
              label="Order no."
              rules={[
                {
                  required: true,
                  message: 'Please enter Order no.',
                },
                {
                  pattern: alphanumericPattern,
                  message: 'Only alphanumeric and characters are allowed.',
                },
              ]}
            >
              <Input onChange={handleInputChange} />
            </Form.Item>

            <Form.Item style={{ width: '100%' }} name="branch" label="Pick up branch" rules={[{ required: true, message: 'Please enter Branch' }]}>
              <Select>
                {branch.map((b) => {
                  return <Option value={b.index}>{b.value}</Option>
                })}
              </Select>
            </Form.Item>
            <div style={{ display: 'flex', width: '100%', gap: 10 }}>
              <Form.Item style={{ width: '100%' }} name="first_name" label="First Name" rules={[{ required: true, message: 'Please enter First Name' }]}>
                <Input />
              </Form.Item>
              <Form.Item style={{ width: '100%' }} name="last_name" label="Last Name" rules={[{ required: true, message: 'Please enter Last Name' }]}>
                <Input />
              </Form.Item>
            </div>

            <Form.Item name="email" label="E-mail" rules={[{ required: true, message: 'Please enter E-mail' }]}>
              <Input />
            </Form.Item>

            <Form.Item name="phone" label="Phone number" rules={[{ required: true, message: 'Please enter Phone number' }]}>
              <Input />
            </Form.Item>

            {/* Slip Image */}
            <Form.Item
              name="slipImage"
              label={
                <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                  <div style={{ color: '#ff4d4f' }}>*</div>
                  <div>Upload Order Slip Reference</div>
                </div>
              }
            >
              {!isPreviewSlip && selectedSlipFile ? (
                <div className="uploaded-image-container">
                  <div style={{ width: '100%', maxWidth: 120, position: 'relative' }}>
                    <Image src={generateImageUrl(selectedSlipFile)} alt="slip-img" style={{ width: '100%' }} />
                    <div className="close-btn" onClick={() => onClearImage('slip')}>
                      X
                    </div>
                  </div>
                </div>
              ) : (
                <div className="uploaded-image-container" style={{ textAlign: 'center' }}>
                  <Upload accept="image/*" listType="picture-card" showUploadList={false} beforeUpload={(file) => handleUploadSlipComplete(file)}>
                    {uploadButton}
                  </Upload>
                </div>
              )}
            </Form.Item>
            {/* Personal Image */}
            <Form.Item
              name="personalImage"
              label={
                <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                  <div style={{ color: '#ff4d4f' }}>*</div>
                  <div>Upload your selfie or image for your personalized mama cup</div>
                </div>
              }
            >
              {!isCropping && selectedPersonalFile ? (
                <div className="uploaded-image-container">
                  <div style={{ width: '100%', maxWidth: 120, position: 'relative' }}>
                    {selectedPersonalFileCrop && <Image src={selectedPersonalFileCrop} alt="pernonal-img-crop" style={{ width: '100%' }} />}
                    <div className="close-btn" onClick={() => onClearImage('personal')}>
                      X
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className="uploaded-image-container"
                  style={{ textAlign: 'center' }}
                  onClick={() => {
                    if (!orderNo) {
                      messageApi.open({
                        type: 'error',
                        content: 'Error: Please Enter Your Order No.',
                      })
                    }
                  }}
                >
                  <Upload
                    listType="picture-card"
                    showUploadList={false}
                    accept="image/*"
                    beforeUpload={(file) => {
                      beforeUpload(file)
                    }}
                    disabled={!orderNo}
                  >
                    {uploadButton}
                  </Upload>
                </div>
              )}
            </Form.Item>
            <Divider />
            <div style={{ width: '100%', marginBottom: 20, textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_KEY} onChange={handleRecaptchaVerify} datasize="compact" />
            </div>
            <Form.Item style={{ width: '100%' }}>
              <div style={{ width: '100%', alignItems: 'center', justifyContent: 'center', display: 'flex', gap: 10 }}>
                <Button type="primary" htmlType="submit" style={{ width: '100%', backgroundColor: '#00b96b' }}>
                  Submit
                </Button>
                <Button
                  style={{ width: '100%' }}
                  onClick={() => {
                    formRef.current.resetFields()
                    setSelectedPersonalFile(null)
                    setSelectedPersonalFileCrop(null)
                    setSelectedPersonalFileNew(null)
                    setSelectedSlipFile(null)
                    setSelectedSlipFileNew(null)
                    setOrderNo(null)
                  }}
                >
                  Clear
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </div>
      {/* <FileUpload /> */}
      {/* </header> */}
    </div>
  )
}
