import React from 'react'
import { Result, Button } from 'antd'
import { useNavigate } from 'react-router-dom'
import './style.css'

export default function ResultPage() {
  const navigate = useNavigate()

  return (
    <div className="result-container">
      <Result
        status="success"
        title="Successfully !"
        subTitle="Success Detail for this result."
        extra={[
          <Button type="primary" key="console" onClick={() => navigate('/')}>
            Go to Homepage
          </Button>,
          //   <Button key="buy">Buy Again</Button>,
        ]}
      />
    </div>
  )
}
