import React, { useState } from 'react'
import Logo from 'assets/images/png/Logo.png'
import './style.css'

import { useNavigate } from 'react-router-dom'

import { MenuOutlined } from '@ant-design/icons'

export default function Header() {
  const [isCollapse, setIsCollapse] = useState(true)
  const navigate = useNavigate()

  console.log('window.location.pathname', window.location.pathname)

  function smoothScroll(targetId) {
    let target = document.getElementById(targetId)
    setIsCollapse((prev) => !prev)

    if (window.location.pathname !== '/') {
      navigate('/')

      setTimeout(() => {
        target = document.getElementById(targetId)
        if (target) {
          target.scrollIntoView({ behavior: 'smooth' })
        }
      }, 250)
    }

    if (target) {
      target.scrollIntoView({ behavior: 'smooth' })
    }
  }
  function gotoPage(page) {
    setIsCollapse((prev) => !prev)
    navigate(page)
  }

  return (
    <>
      <div className="menu-container">
        <div className="menu" onClick={() => smoothScroll('home')}>
          HOME
        </div>
        <div>•</div>
        <div className="menu" onClick={() => smoothScroll('about_us')}>
          ABOUT US
        </div>
        <div>•</div>
        <div className="menu" onClick={() => smoothScroll('menu')}>
          MENU
        </div>
        <div style={{ width: 180 }} onClick={() => smoothScroll('home')}>
          <div className="Logo" style={{ margin: 0, marginTop: 40 }}>
            <img src={Logo} alt="logo" width={180} style={{ marginTop: -15 }} />
          </div>
        </div>
        <div className="menu" onClick={() => gotoPage('/my-own-cup')}>
          MY OWN CUP
        </div>
        <div>•</div>
        <div className="menu" onClick={() => smoothScroll('experience')}>
          EXPERIENCE
        </div>
        <div>•</div>
        <div className="menu" onClick={() => smoothScroll('contact_us')}>
          CONTACT US
        </div>
      </div>
      <div className="menu-container menu-container-responsive">
        <div style={{ width: 180, marginTop: 100 }}>
          <div className="Logo">
            <img src={Logo} alt="logo" className="logo-image" />
          </div>
        </div>
        <div className="menu" style={{ color: 'white', fontSize: 24, cursor: 'pointer' }}>
          <MenuOutlined onClick={() => setIsCollapse((prev) => !prev)} />
        </div>
      </div>

      <div className={`menu-responsive-bg ${isCollapse ? 'is-inactive-bg' : 'is-active-bg'}`} onClick={() => setIsCollapse((prev) => !prev)}></div>
      <div className={`menu-responsive ${isCollapse ? 'is-inactive' : 'is-active'}`}>
        <div className="logo-menu">
          <img src={Logo} alt="logo" className="logo-image-menu" />
        </div>
        <div className="menu" onClick={() => smoothScroll('home')} style={{ textAlign: 'center', width: '100%' }}>
          HOME
        </div>
        <div className="menu" onClick={() => smoothScroll('about_us')} style={{ textAlign: 'center', width: '100%' }}>
          ABOUT US
        </div>
        <div className="menu" onClick={() => smoothScroll('menu')} style={{ textAlign: 'center', width: '100%' }}>
          MENU
        </div>
        <div className="menu" onClick={() => gotoPage('/my-own-cup')} style={{ textAlign: 'center', width: '100%' }}>
          MY OWN CUP
        </div>
        <div className="menu" onClick={() => smoothScroll('contact_us')} style={{ textAlign: 'center', width: '100%' }}>
          CONTACT US
        </div>
        <div className="menu" onClick={() => smoothScroll('experience')} style={{ textAlign: 'center', width: '100%' }}>
          EXPERIENCE
        </div>
      </div>
    </>
  )
}
