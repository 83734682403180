import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { ConfigProvider } from 'antd'
import MyOwnCup from './containers/myOwnCup'
import Result from 'containers/result'
// import Template from './containers/template'
import { Layout } from 'antd'
import { MainStore } from './core/store'
import 'antd/dist/reset.css'
import './assets/css/variables.css'
import 'react-image-crop/dist/ReactCrop.css'
import { useState } from 'react'
import HomePage from 'containers/home'
import Header from 'components/header'

const { Content } = Layout

function App() {
  const [isLoading, setIsLoading] = useState(false)

  return (
    <MainStore.Provider value={{ isLoading, setIsLoading }}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#00b96b',
          },
        }}
      >
        <BrowserRouter>
          <Layout>
            <Header />
            <Content>
              <Routes>
                {/*exact คือ path ต้อง match เท่านั้น ถ้า home ต้อง home เท่านั้น ถ้าไม่มี exact เข้า path homes จะเข้ามาที่ component(element) ดังกล่าวได้*/}
                <Route exact path="/" element={<HomePage />} />
                <Route exact path="/my-own-cup" element={<MyOwnCup />} />
                <Route exact path="/result" element={<Result />} />
                {/* <Route exact path="/template" element={<Template />} /> */}
              </Routes>
            </Content>
          </Layout>
        </BrowserRouter>
      </ConfigProvider>
    </MainStore.Provider>
  )
}

export default App
