import React from "react";

import "./style.css";

export default function SectionPage({ children, isFill, className, id }) {
  return (
    <section
      id={id}
      className={`section-container ${isFill ? "is-fill" : ""} ${className}`}
    >
      {children}
    </section>
  );
}
