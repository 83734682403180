import React from "react";
import "./style.css";
import SectionPage from "components/section";

import HeaderImage from "assets/images/png/header-image.png";

import { Carousel, Image } from "antd";

import ChefRecommand from "assets/images/png/chef_recommand.png";

import Logo from "assets/images/png/Logo.png";

// Slide Images
import Slide01 from "assets/images/png/Slide_01.jpg";
import Slide02 from "assets/images/png/Slide_02.jpg";
import Slide03 from "assets/images/png/Slide_03.jpg";
import Slide04 from "assets/images/png/Slide_04.jpg";
import Slide05 from "assets/images/png/Slide_05.jpg";
import Slide06 from "assets/images/png/Slide_06.jpg";
import Slide07 from "assets/images/png/Slide_07.jpg";
import Slide08 from "assets/images/png/Slide_08.jpg";
import Slide09 from "assets/images/png/Slide_09.jpg";
import Slide10 from "assets/images/png/Slide_10.jpg";
import Slide11 from "assets/images/png/Slide_11.jpg";
import Slide12 from "assets/images/png/Slide_12.jpg";

// Signature Image
import Signature01 from "assets/images/png/signature_01.jpg";
import Signature02 from "assets/images/png/signature_02.jpg";
import Signature03 from "assets/images/png/signature_03.jpg";
import Signature04 from "assets/images/png/signature_04.jpg";
import Signature05 from "assets/images/png/signature_05.jpg";

// Menu Image
import Menu1 from "assets/images/png/menu1.jpg";
import Menu2 from "assets/images/png/menu2.jpg";

export default function HomePage() {
  const imageList = [
    Slide01,
    Slide02,
    Slide03,
    Slide04,
    Slide05,
    Slide06,
    Slide07,
    Slide08,
    Slide09,
    Slide10,
    Slide11,
    Slide12,
  ];
  const signatureImageList = [
    Signature01,
    Signature02,
    Signature03,
    Signature04,
    Signature05,
  ];

  const onChange = (currentSlide) => {
    // console.log(currentSlide)
  };

  return (
    <div className="main-container" id="home">
      <SectionPage isFill={true}>
        <div
          className="header-content"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            // height: "100vh",
          }}
        >
          <img
            src={HeaderImage}
            alt="header-logo"
            className="header-logo"
            width={"50%"}
          />
          <div className="header-detail">
            <h1 style={{ fontSize: "clamp(28px, 2.5vw, 40px)" }}>
              Craze Mama ร้านอาหารไทย
              ประสบการณ์ความอร่อยครั้งใหม่กับเมนูอาหารที่หลากหลายไม่ซ้ำใคร.
            </h1>
            <div>
              <div
                style={{
                  fontSize: "clamp(14px, 2.5vw, 18px)",
                  color: "#f9c712",
                }}
              >
                จุดเริ่มต้นของ Craze Mama
                เกิดจากความตั้งใจที่อยากให้ลูกค้าได้รับความอร่อยและประสบการณ์สุดพิเศษที่ไม่เคยได้รับที่ไหนมาก่อน
                กับจุดเด่นของร้านที่รวบรวมของดีประเทศไทยเอาไว้
                ไม่ว่าจะเป็นเมนูอาหารไทยที่ผสมผสานความคิดสร้างสรรค์
                กับการยกระดับมาม่าแบรนด์บะหมี่กึ่งสำเร็จรูปอันดับหนึ่งที่อยู่คู่กับคนไทยมายาวนานจนเกิดเป็นเมนูสุดพิเศษ
                พร้อมกับบริการที่แตกต่าง สร้างสรรค์ ไม่เหมือนใคร
              </div>
            </div>
          </div>
        </div>
      </SectionPage>

      <SectionPage id="about_us">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "100vh",
            padding: "130px 75px 130px 75px",
          }}
        >
          {/* <h1 style={{ fontSize: 40, textAlign: 'center', marginBottom: -20 }}>
            <img src={Logo} alt="Logo" width={250} />
          </h1> */}
          <h1
            style={{
              fontSize: "clamp(26px, 2.5vw, 40px)",
              textAlign: "center",
              marginBottom: 20,
            }}
          >
            ABOUT CRAZE MAMA
          </h1>
          <div style={{ padding: "0 20%", textAlign: "center" }}>
            <h1
              style={{
                fontSize: "clamp(20px, 2.5vw, 28px)",
                textAlign: "center",
                margin: 0,
                marginBottom: 10,
              }}
            >
              ผสมผสานความคิดสร้างสรรค์จนเกิดเป็นเมนูสุดพิเศษ
            </h1>
            <div
              style={{
                fontSize: "clamp(14px, 2.5vw, 18px)",
                textAlign: "center",
                marginBottom: 30,
              }}
            >
              เมนูอาหารไทยที่มีรสชาติและเอกลักษณ์เฉพาะตัวผสมผสานกับความคิดสร้างสรรค์
              พร้อมยกระดับมาม่าเมนูที่หลายคนคิดว่าธรรมดาสู่เมนูสุดพิเศษ
              พร้อมเมนูอาหารไทยออริจินัลและอาหารไทยฟิวชั่น
              ไม่ว่าจะเป็นเย็นตาโฟต้มยำทะเลหม้อไฟ น้ำตกแตงโม ปูผัดผงกะหรี่ ผัดไท
              และเมนูอื่นๆอีกมากมาย
            </div>
          </div>
          <div className="menu-slide-content">
            <div>
              <h1
                style={{
                  fontSize: "clamp(20px, 2.5vw, 30px)",
                  textAlign: "center",
                }}
              >
                MENU
              </h1>
              <Carousel
                afterChange={onChange}
                className="carousel-custom-style"
                autoplay
                speed={2}
              >
                {imageList.map((image) => {
                  return (
                    <div>
                      <h3 className="content-style-slide">
                        <Image
                          src={image}
                          alt="test"
                          className="carousel-custom-style"
                        />
                      </h3>
                    </div>
                  );
                })}
              </Carousel>
            </div>
            <div>
              <h1
                style={{
                  fontSize: "clamp(20px, 2.5vw, 30px)",
                  textAlign: "center",
                }}
              >
                SIGNATURE MENU
              </h1>
              <Carousel
                afterChange={onChange}
                className="carousel-custom-style"
                autoplay
                speed={2}
              >
                {signatureImageList.map((image) => {
                  return (
                    <div>
                      <h3 className="content-style-slide">
                        <Image
                          src={image}
                          alt="test"
                          className="carousel-custom-style"
                        />
                      </h3>
                    </div>
                  );
                })}
              </Carousel>
            </div>
          </div>
          <h1
            style={{
              display: "flex",
              margin: 0,
              justifyContent: "space-around",
              color: "#d14124",
              fontSize: 36,
              marginTop: 30,
            }}
          >
            <Image src={ChefRecommand} alt="ChefRecommand" preview={false} />
          </h1>
        </div>
      </SectionPage>

      <SectionPage id="experience" isFill={true}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            justifyContent: "start",
            height: "100vh",
            padding: "120px 75px",
          }}
        >
          <h1
            style={{ textAlign: "left", fontSize: "clamp(26px, 2.5vw, 36px)" }}
          >
            EXPERIENCE
          </h1>
          <h1
            style={{
              textAlign: "center",
              fontSize: "clamp(26px, 2.5vw, 36px)",
              width: "100%",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
            }}
          >
            COMING SOON...
          </h1>
        </div>
      </SectionPage>

      <SectionPage id="menu">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            justifyContent: "start",
            minHeight: "100vh",
            padding: "120px 75px",
          }}
        >
          <h1 style={{ textAlign: "left", fontSize: 40 }}>MENU</h1>
          <div className="menu-content">
            <Image src={Menu1} alt="" style={{ width: "100%" }} />
            <Image src={Menu2} alt="" style={{ width: "100%" }} />
          </div>
        </div>
      </SectionPage>

      <SectionPage isFill={true} id="contact_us">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            justifyContent: "start",
            height: "auto",
            padding: "120px 75px",
            width: "100%",
          }}
        >
          <h1
            style={{ textAlign: "left", fontSize: "clamp(26px, 2.5vw, 36px)" }}
          >
            CONTACT US
          </h1>
          <div className="contact-us-content">
            {/* <div style={{ display: 'flex', flexDirection: 'column', gap: 20, width: '100%' }}> */}
            <div style={{ width: "100%" }}>
              <h2 style={{ fontSize: "clamp(20px, 2.5vw, 28px)" }}>
                CREZE MAMA TERMINAL 21
              </h2>
              <h2 style={{ fontSize: "clamp(20px, 2.5vw, 28px)" }}>TEL: -</h2>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3875.6782086485036!2d100.55789127570925!3d13.73792188665249!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e29f1e6e996bb9%3A0x3383716c9b049379!2sTerminal%2021%20Asok!5e0!3m2!1sen!2sth!4v1692346734517!5m2!1sen!2sth"
                width="100%"
                height="450"
                style={{ border: 0 }}
                allowfullscreen={false}
              ></iframe>
            </div>
            <div style={{ width: "100%" }}>
              <h2 style={{ fontSize: "clamp(20px, 2.5vw, 28px)" }}>
                CREZE MAMA ICON SIAM
              </h2>
              <h2 style={{ fontSize: "clamp(20px, 2.5vw, 28px)" }}>TEL: -</h2>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3875.868845778306!2d100.5067284!3d13.7263893!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e299cd81f01815%3A0x1660bd86761a92f4!2sICONSIAM!5e0!3m2!1sen!2sth!4v1692347755942!5m2!1sen!2sth"
                width="100%"
                height="450"
                style={{ border: 0 }}
                allowfullscreen={false}
              ></iframe>
            </div>
            {/* </div> */}
          </div>
        </div>
      </SectionPage>
    </div>
  );
}
